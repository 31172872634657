.BreadcrumbsWrapper {
  list-style: none;
  padding: 0;
  margin: 0;
}

.BreadcrumbItem {
  display: inline;
  padding: 0 12px;
  position: relative;

  &:first-child {
    padding-left: 0;
  }

  &:last-child:after {
    content: ''
  }

  &:after {
    content: '/';
    display: block;
    position: absolute;
    top: -1px;
    right: -2px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.BreadcrumbLink {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.2s;
  padding: 0 4px;
  border-radius: 4px;
  height: 22px;
  display: inline-block;
  margin-inline: -4px;

  &:hover {
    color: rgba(0, 0, 0, 0.88);
    background-color: rgba(0, 0, 0, 0.06);
  }
}
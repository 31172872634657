.wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 50vw;
}

.line {
    display: flex;
    flex-basis: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.uploadButton {
    margin-right: 20px;
    max-width: 250px;
}
